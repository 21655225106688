exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-posts-2024-02-06-ways-to-reduce-diaper-costs-index-mdx": () => import("./../../../src/posts/2024-02-06-ways-to-reduce-diaper-costs/index.mdx" /* webpackChunkName: "component---src-posts-2024-02-06-ways-to-reduce-diaper-costs-index-mdx" */),
  "component---src-posts-2024-02-07-are-diaper-costs-increasing-index-mdx": () => import("./../../../src/posts/2024-02-07-are-diaper-costs-increasing/index.mdx" /* webpackChunkName: "component---src-posts-2024-02-07-are-diaper-costs-increasing-index-mdx" */),
  "component---src-posts-2024-02-21-best-changing-pads-for-hassle-free-diaper-duty-index-mdx": () => import("./../../../src/posts/2024-02-21-best-changing-pads-for-hassle-free-diaper-duty/index.mdx" /* webpackChunkName: "component---src-posts-2024-02-21-best-changing-pads-for-hassle-free-diaper-duty-index-mdx" */),
  "component---src-posts-2024-03-12-kirkland-vs-honest-index-mdx": () => import("./../../../src/posts/2024-03-12-kirkland-vs-Honest/index.mdx" /* webpackChunkName: "component---src-posts-2024-03-12-kirkland-vs-honest-index-mdx" */),
  "component---src-posts-2024-03-12-pamper-vs-kirkland-index-mdx": () => import("./../../../src/posts/2024-03-12-Pamper-vs-kirkland/index.mdx" /* webpackChunkName: "component---src-posts-2024-03-12-pamper-vs-kirkland-index-mdx" */),
  "component---src-posts-2024-03-15-huggies-vs-pampers-index-mdx": () => import("./../../../src/posts/2024-03-15-Huggies-vs-Pampers/index.mdx" /* webpackChunkName: "component---src-posts-2024-03-15-huggies-vs-pampers-index-mdx" */),
  "component---src-posts-2024-03-15-kirkland-vs-huggies-index-mdx": () => import("./../../../src/posts/2024-03-15-kirkland-vs-huggies/index.mdx" /* webpackChunkName: "component---src-posts-2024-03-15-kirkland-vs-huggies-index-mdx" */),
  "component---src-posts-2024-03-15-non-toxic-diapers-index-mdx": () => import("./../../../src/posts/2024-03-15-Non-Toxic-Diapers/index.mdx" /* webpackChunkName: "component---src-posts-2024-03-15-non-toxic-diapers-index-mdx" */),
  "component---src-posts-2024-04-07-top-diaper-wipes-you-need-for-your-baby-skin-index-mdx": () => import("./../../../src/posts/2024-04-07-top-diaper-wipes-you-need-for-your-baby-skin/index.mdx" /* webpackChunkName: "component---src-posts-2024-04-07-top-diaper-wipes-you-need-for-your-baby-skin-index-mdx" */),
  "component---src-posts-2024-04-23-coterie-vs-honest-index-mdx": () => import("./../../../src/posts/2024-04-23-Coterie-Vs-Honest/index.mdx" /* webpackChunkName: "component---src-posts-2024-04-23-coterie-vs-honest-index-mdx" */),
  "component---src-posts-2024-04-24-diaper-rash-index-mdx": () => import("./../../../src/posts/2024-04-24-Diaper-Rash/index.mdx" /* webpackChunkName: "component---src-posts-2024-04-24-diaper-rash-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-02-06-ways-to-reduce-diaper-costs-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-02-06-ways-to-reduce-diaper-costs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-02-06-ways-to-reduce-diaper-costs-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-02-07-are-diaper-costs-increasing-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-02-07-are-diaper-costs-increasing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-02-07-are-diaper-costs-increasing-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-02-21-best-changing-pads-for-hassle-free-diaper-duty-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-02-21-best-changing-pads-for-hassle-free-diaper-duty/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-02-21-best-changing-pads-for-hassle-free-diaper-duty-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-12-kirkland-vs-honest-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-03-12-kirkland-vs-Honest/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-12-kirkland-vs-honest-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-12-pamper-vs-kirkland-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-03-12-Pamper-vs-kirkland/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-12-pamper-vs-kirkland-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-15-huggies-vs-pampers-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-03-15-Huggies-vs-Pampers/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-15-huggies-vs-pampers-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-15-kirkland-vs-huggies-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-03-15-kirkland-vs-huggies/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-15-kirkland-vs-huggies-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-15-non-toxic-diapers-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-03-15-Non-Toxic-Diapers/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-03-15-non-toxic-diapers-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-04-07-top-diaper-wipes-you-need-for-your-baby-skin-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-04-07-top-diaper-wipes-you-need-for-your-baby-skin/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-04-07-top-diaper-wipes-you-need-for-your-baby-skin-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-04-23-coterie-vs-honest-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-04-23-Coterie-Vs-Honest/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-04-23-coterie-vs-honest-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2024-04-24-diaper-rash-index-mdx": () => import("./../../../src/templates/blogPost.js?__contentFilePath=/opt/build/repo/src/posts/2024-04-24-Diaper-Rash/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2024-04-24-diaper-rash-index-mdx" */)
}

